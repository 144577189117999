<template>
  <div class="live">
    <div class="player-wrapper">
      <LivePlayer
        :finishing="finishing"
        :selectedTime="selectedTime"
        :token="token"
        :detail="detail"
        :playbackIndex="playbackIndexNum"
        @clickFeedbackL="clickFeedbackL"
        @initLiveVod="initLiveVod"
        @initPlaybackList="initPlaybackList"
      ></LivePlayer>
    </div>
    <div class="detail-wrapper pc">
      <LiveSchedule
        :detail="detail"
        :selectedTime="selectedTime"
        :token="token"
        @changeTime="changeTime"
        @clickFeedback="showFeedback"
      ></LiveSchedule>
      <div class="right">
        <LivePPT></LivePPT>
        <LiveSponsor></LiveSponsor>
      </div>
    </div>
    <div class="tabs mob">
      <slot v-if="livevod!=1">
        <div class="tab-bar" :class="'item' + selectedTab">
          <div
            class="bar-item"
            :class="parseInt(selectedTab) === index ? 'selected' : ''"
            v-for="(item, index) in tabList"
            :key="index"
            :data-id="index"
            @click="toggleTab"
          >{{ item }}
          </div>
        </div>
        <div class="tab-content">
          <LiveSchedule
            v-if="selectedTab === '0'"
            :detail="detail"
            :selectedTime="selectedTime"
            @changeTime="changeTime"
            @clickFeedback="showFeedback"
          ></LiveSchedule>
          <LivePPT v-else-if="selectedTab === '1'"></LivePPT>
          <LiveSponsor v-else-if="selectedTab === '2'"></LiveSponsor>
          <AskQuestionMob
            v-else-if="selectedTab === '3'"
            :detail="detail"
            :selectedTime="selectedTime"
            :token="token"
          ></AskQuestionMob>
        </div>
      </slot>
      <slot v-else>
        <div class="tab-bar" :class="'item' + selectedTab">
          <div
            class="bar-item"
            :class="parseInt(selectedTab) === index ? 'selected' : ''"
            v-for="(item, index) in tabListVod"
            :key="index"
            :data-id="index"
            @click="toggleTab"
          >{{ item }}
          </div>
        </div>
        <div class="tab-content">
          <LiveSchedule
            v-if="selectedTab === '0'"
            :detail="detail"
            :selectedTime="selectedTime"
            @changeTime="changeTime"
            @clickFeedback="showFeedback"
          ></LiveSchedule>
          <PlayBackListMob 
            v-else-if="selectedTab === '1'" 
            :_playbackList="playbackList" 
            @playbackIndex="getPlaybackIndex" 
          ></PlayBackListMob>
          <LivePPT v-else-if="selectedTab === '2'"></LivePPT>
          <LiveSponsor v-else-if="selectedTab === '3'"></LiveSponsor>
          <AskQuestionMob
            v-else-if="selectedTab === '4'"
            :detail="detail"
            :selectedTime="selectedTime"
            :token="token"
          ></AskQuestionMob>
        </div>
      </slot>
    </div>
    <Feedback
      v-if="feedbackCourseId !== ''"
      :courseId="feedbackCourseId"
      :token="token"
      @hideFeedback="hideFeedback"
    ></Feedback>
  </div>
</template>

<script>
import { sid } from "@/utils/api.js";

export default {
  name: "TwoSHLive",
  components: {
    LivePlayer: () => import("@/components/live/LivePlayer.vue"),
    LiveSchedule: () => import("@/components/live/LiveSchedule.vue"),
    LivePPT: () => import("@/components/live/LivePPT.vue"),
    LiveSponsor: () => import("@/components/live/LiveSponsor.vue"),
    Feedback: () => import("@/components/live/Feedback.vue"),
    // AskQuestion: () => import("@/components/Live/AskQuestion.vue"),
    AskQuestionMob: () => import("@/components/live/AskQuestionMob.vue"),
    PlayBackListMob: () => import("@/components/live/PlayBackListMob.vue"),
  },
  data() {
    let _this = this;
    return {
      tabList: _this.global.year=='2022sh'?["日程", "扫码查看PPT", "赞助商"]:["日程", "扫码查看PPT", "赞助商", "提问"],
      tabListVod: _this.global.year=='2022sh'?["日程", "回放列表", "扫码查看PPT", "赞助商"]:["日程", "回放列表", "扫码查看PPT", "赞助商", "提问"],
      sponsorList: [],
      selectedTab: "0",
      timeList: [],
      groupId: "",
      finishing: {},
      detail: {},
      selectedTime: "",
      liveDetail: {},
      token: "",
      feedbackCourseId: "",
      // 回放
      livevod: "",
      playbackList: "",
      playbackIndexNum: 0,
    };
  },
  mounted() {
    this.groupId = this.$route.query.groupid;
    this.selectedTime = this.$route.query.time;
    // this.checkToken(); // 调试用，正式上线为下列方法(检测用户登录状态与权限)
    if (this.$route.query.token) {
      this.$route.query.token = token;
      this.checkLook();
    } else if (this.checkToken()) {
      this.checkLook();
    }
    this.regularCheckToken();
  },
  methods: {
    // 回放
    initLiveVod(type) {
      this.livevod = parseInt(type); // 1为回放
      this.$forceUpdate();
    },
    initPlaybackList(listStr) {
      this.playbackList = listStr;
    },
    getPlaybackIndex(index) {
      this.playbackIndexNum = parseInt(index);
    },
    // 反馈
    clickFeedbackL(popCourseId) {
      this.showFeedback(popCourseId);
    },
    showFeedback(courseId) {
      this.feedbackCourseId = parseInt(courseId);
    },
    hideFeedback() {
      this.feedbackCourseId = "";
    },
    regularCheckToken() {
      window.setInterval(() => {
        this.checkLocalToken();
      }, 10 * 60 * 1000);
    },
    checkLocalToken() {
      const url =
        "https://www.msup.com.cn/api/top-api/user-member?token=" + this.token;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.status === "200") {
            console.log("200");
          } else if (res.status === "1011") {
            console.log("用户未登录");
            this.$toast("您已在其他浏览器登录，请重新登录", 1000);
            this.$router.go(0);
          } else {
            console.log("获取用户信息失败");
            this.$router.go(0);
          }
        });
    },
    changeTime(time) {
      this.selectedTime = time;
    },
    getLiveInfo() {
      const url =
        "https://api.msup.com.cn/scheduling/get-schedule-by-group/" + sid(this.global.year);

      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno === "0") {
            this.finishing = res.data.finishing[this.selectedTime];
            this.detail = res.data.datas;
          } else {
            console.log("获取数据失败");
          }
        });
    },
    toggleTab(e) {
      this.selectedTab = e.currentTarget.dataset.id;
    },
    checkToken() {
      const token = window.localStorage.getItem("token");
      if (token === null || !token) {
        window.localStorage.removeItem("logins");
        window.localStorage.removeItem("token");
        let linkurl = this.$route.fullPath + `${this.global.hashParam.qd?`${this.$route.fullPath.indexOf('?')==-1?'?':'&'}qd=`+this.global.hashParam.qd:''}`;
        window.localStorage.setItem("linkurl", linkurl);
        // localStorage.setItem("linkurl", window.location.href);
        this.$router.push("/2022sh/login");
        return false;
      } else {
        this.token = token;
        this.getLiveInfo();
        return true;
      }
    },
    checkLook() {
      const url =
        "https://www.msup.com.cn/api/top-api/check-look" +
        "?sid=" +
        sid(this.global.year) +
        "&token=" +
        this.token +
        "&groupId=" +
        this.groupId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.status === "200") {
            // console.log("200");
          } else if (res.status === "1070") {
            this.$toast("您没有权限观看本场直播", 1000);
            setTimeout(() => {
              this.$router.replace({
                path: `/2022sh/live_schedule`,
              });
            }, 1000);
          } else if (res.status === "1071") {
            this.$toast("您没有权限观看本场直播", 1000);
            setTimeout(() => {
              this.$router.replace({
                path: `/2022sh/live_schedule`,
              });
            }, 1000);
          } else if (res.status === "1072") {
            this.$toast("当前时间与本场直播时间不符", 1000);
            setTimeout(() => {
              this.$router.replace({
                path: `/2022sh/live_schedule`,
              });
            }, 1000);
          } else if (res.status === "1073") {
            this.$toast("您没有线上门票，购买后即可观看", 1000);
            setTimeout(() => {
              this.$router.push("/2022sh/order");
            }, 1000);
          } else if (res.status === "1011") {
            this.$toast("您未登录或当前登录状态已过期", 1000);
            window.localStorage.removeItem("logins");
            window.localStorage.removeItem("token");
            let linkurl = this.$route.fullPath + `${this.global.hashParam.qd?`${this.$route.fullPath.indexOf('?')==-1?'?':'&'}qd=`+this.global.hashParam.qd:''}`;
            window.localStorage.setItem("linkurl", linkurl);
            setTimeout(() => {
              this.$router.push("/2022sh/login");
            }, 1000);
          } else {
            console.log("操作失败");
            this.$toast("您没有权限观看本场直播", 1000);
            setTimeout(() => {
              this.$router.replace({
                path: `/2022sh/live_schedule`,
              });
            }, 1000);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  text-align: left;
}
@media (min-width: 1000px) {
  .mob {
    display: none;
  }
}
@media (max-width: 1001px) {
  .detail-wrapper.pc {
    display: none;
  }
}
@media (min-width: 751px) and (max-height: 1000px) {
  .live {
    z-index: 110;
  }
}
@media (max-width: 768px) {
  .live {
    margin-top: 0 !important;
  }
}
// @media (min-width: 767px) {
//   .live {
//     margin-top: 150px;
//   }
// }
.live {
  // position: relative;
  background: #f7f7f7;
}
.player-wrapper {
  background: #262626;
}
.detail-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 43px;
  padding-bottom: 60px;
  max-width: 1190px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  // .right {
  // }
}
.tabs {
  padding-left: 5.8vw;
  padding-right: 5.8vw;
  padding-bottom: 30px;
  .tab-bar {
    display: flex;
    justify-content: space-between;
    .bar-item {
      position: relative;
      // margin-right: 8vw;
      padding-bottom: 18px;
      padding-top: 19px;
      color: #909090;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        display: block;
        opacity: 0;
        // width: 24px;
        width: 86%;
        height: 3px;
        background: #0a70c5;
        transition: all linear 300ms;
      }
      &.selected {
        color: #222222;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .tab-content {
    padding-top: 10px;
  }
}
</style>
