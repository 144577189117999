<template>
  <div id="schedule">
    <!-- 日程安排 -->
    <!-- <div class="banner spc">
      <h1>大会日程</h1>
      <p>年度技术创新及研发实践的架构案例</p>
    </div> -->
    <Title :title="title" :description="description"></Title>
    <div class="schedule_con">
      <div id="menu" class="spc" style="display: none">
        <ul>
          <li>
            <a href="#item1" class="dianji current">DAY1</a>
          </li>
          <li>
            <a href="#item2" class="dianji">DAY2</a>
          </li>
        </ul>
      </div>
      <div class="richeng spc">
        <ul class="daohang">
          <li
            v-for="(item, index) in days"
            :class="
              index == 0
                ? 'active col-lg-4 col-md-4 col-sm-4'
                : 'col-lg-4 col-md-4 col-sm-4'
            "
            :key="'r_daohang_' + index"
          >
            <a
              :href="'#item' + (index + 1)"
              class="dianji"
              @click="clickLi_($event)"
            >
              <div>
                <h1>DAY{{ index + 1 }}</h1>
                <span>{{ item }}</span>
              </div>
            </a>
          </li>
        </ul>
        <ul class="content content_spc"></ul>
      </div>
      <div class="clearboth spc"></div>
      <!-- smob -->
      <div class="richeng smob">
        <ul class="daohang smob">
          <li v-for="(item, index) in days" :key="'r_daohang_' + index"
             :class="index == 0 ? 'active col-lg-4 col-md-4 col-sm-4' : 'col-lg-4 col-md-4 col-sm-4'"
          >
            <a class="dianji" :href="'#item' + (index + 1)" @click="showDay($event,index)">
                <h1>DAY{{ index + 1 }}</h1>
            </a>
          </li>
        </ul>
        <div class="content content_smob">
          <ul class="day_menu">
            <li v-for="(it1,i1) in project_smob[day]" :key="'smob_courses_'+i1" 
                :style="courses_index==i1?'background: #e0e0e0;':'background: #f9f9f9;'" @click="showCourses(i1)">
              {{it1['title']}}
            </li>
          </ul>
          <div class="clearboth smob"></div>
          <ul class="day_list">
          <!--  style="display:none;" -->
            <!-- {{project_smob[day][0]}} -->
            <!-- <li v-for="(it2,i2) in project_smob[day][courses_index]['list']" :key="'smob_course_'+i2">
              <h1>{{it2.courseTitle}}</h1>
              <p>
                <span>{{it2.lecturers[0].name}}</span> {{it2.lecturers[0].company}} {{it2.lecturers[0].position}}
              </p>
              <p>
                {{project_smob[day][courses_index].date}}
              </p>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="clearboth smob"></div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title';
import $ from "jquery";

export default {
  name: "Schedule",
  data() {
    return {
      days: [],
      day: 0,
      courses_index: 0,
      project: [],
      project_smob: [],
      title: '大会日程',
      description: '年度技术创新及研发实践的架构案例',
      course_list: [[],[]],
    };
  },
  components: { Title },
  methods: {
    clickLi_: function (e) {
      // v-for动态生成的dianji另行绑定click以防错误
      $(e.currentTarget).parents("li").addClass("active");
      $(e.currentTarget).parents("li").siblings().removeClass("active");
      // e.currentTarget.parentElement.className = "active col-lg-4 col-md-4 col-sm-4";
      $("html,body").animate(
        { scrollTop: $($(e.currentTarget).attr("href")).offset().top },
        500
      );
      return false;
    },
    clickLi: function () {
      $(".daohang>li>a").click(function () {
        $(this).parents("li").addClass("active");
        $(this).parents("li").siblings().removeClass("active");
      });
      $(".dianji").click(function () {
        $("html,body").animate(
          { scrollTop: $($(this).attr("href")).offset().top },
          500
        );
        return false;
      });
      // menu点击
      // $(document).ready(function () {
      $(window).scroll(function () {
        var items = $(".content_spc").find(".show");
        var menu = $("#menu");
        var top = $(document).scrollTop();
        var currentId = "";
        items.each(function () {
          var m = $(this);
          if (top > m.offset().top - 500) {
            currentId = "#" + m.attr("id");
          } else {
            return false;
          }
        });
        var currentLink = menu.find(".current");
        if (currentId && currentLink.attr("href") != currentId) {
          currentLink.removeClass("current");
          menu.find("a[href='" + currentId + "']").addClass("current");
        }
      });
      // });
      // menu隐藏与显示
      $("#menu").css({ display: "none" });
      $(function () {
        $(window).scroll(function () {
          if ($(window).scrollTop() > 1600) {
            $("#menu").fadeIn(500);
          } else {
            $("#menu").fadeOut(500);
          }
        });
      });
    },
    showDay: function(e,i){
      // v-for动态生成的dianji另行绑定click以防错误
      $(e.currentTarget).parents("li").addClass("active");
      $(e.currentTarget).parents("li").siblings().removeClass("active");
      this.day = i;
      this.courses_index = 0;
      this.addCourses();
    },
    showCourses: function(i){
      // this.day   
      $(".day_list").css({'display':'block'});
      this.courses_index = parseInt(i);
      this.addCourses();
      // console.log('courses_index',i,this.courses_index,this.day);
    },
    addCourses: function(){
      // console.log("add ",this.project_smob);
      let day = this.day;
      let courses_index = this.courses_index;
      let list = this.project_smob[day][courses_index].list;
      let llen = list.length;
      $(".day_list li").remove();
      for(let l=0;l<llen;l++){
        let li = 
          `
            <li style="width: 94%;margin: 0 auto;background: #f9f9f9;padding: 20px;margin-bottom: 10px;" class="day_list_li${l}">
              <a href="/course?id=${list[l].courseId}" style="color: #000000;text-decoration: none;">
                <h1 style="font-size: 20px;font-weight: 500;margin-bottom: 10px;">${list[l].courseTitle}</h1>
                <p style="margin-bottom: 5px;">
                  <span style="font-weight: 500;margin-right: 5px;">${list[l].lecturers[0].name}</span> ${list[l].lecturers[0].company} ${list[l].lecturers[0].position}
                </p>
                <p>
                  <span class="glyphicon glyphicon-time title_time" style="margin-right: 8px;"></span>
                  ${this.project_smob[day][courses_index].date} ${list[l].time}
                </p>
              </a>
            </li>
          `
        $(".day_list").append(li);
        // $(".day_list_li"+l).onclick = ()=>{
        //   this.toNext('course',list[l].courseId);
        // }
      }
    },
    formatTime(timestamp, tag) {
      const time = new Date(timestamp * 1000);
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let week = time.getDay();
      switch (week) {
        case 0:
          week = "星期日";
          break;
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
        default:
          break;
      }
      let res = "";
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (tag === "start") {
        res = `${year}.${month}.${day}   ${week}`;
      } else if (tag === "day") {
        res = `${year}.${month}.${day}`;
      } else {
        res = `${month}.${day}`;
      }
      return res;
    },
    getSpeech: function(){
      var project = [];
      var url = "https://api.msup.com.cn/group/get-group-project/2682";
      this.$http
        .jsonp(url)
        .then(data => {
          return data.json();
        })
        .then(data => {
          if(data.errno == 0){
            // console.log("getSpeech data: ",data.data);
            project = data.data;
            this.project = project;
          }
        })
    },
    getSchedule: function () {
      // var url = "https://api.msup.com.cn/course/video-list/2682";
      var url = "https://api.msup.com.cn/scheduling/get-schedule/2682";
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.errno == 0) {
            // console.log("schedule ",data.data);
            // console.log("project: ",this.project);

            // smob
            // 专题
            let fkeys = Object.keys(data.data.finishing);
            for(let f=0;f<fkeys.length;f++){
              this.project_smob.push([]);
              let dkeys = Object.keys(data.data.finishing[fkeys[f]]);
              
              var dnums = dkeys.length;
              let has_am = false;
              let has_pm = false;
              var dstr = JSON.stringify(dkeys);
              if (dstr.indexOf("am") != -1) {
                has_am = true; //判断是否有上午
              }
              if (dstr.indexOf("pm") != -1) {
                has_pm = true; //判断是否有下午
              }
              if (has_am && has_pm) {
                dnums = dnums / 2; //根绝上下午判断循环次数
              }

              for(let d=0;d<dnums;d++){
                let amData = data.data.finishing[fkeys[f]][(d+1)+'am'];
                let pmData = data.data.finishing[fkeys[f]][(d+1)+'pm'];
                // console.log(amData,this.formatTime(amData.date,'day'));
                // console.log(pmData,this.formatTime(pmData.date,'day'));
                this.project_smob[f].push({title:'', date:this.formatTime(pmData.date,'day'), list:[]});
                this.project_smob[f][d].title = amData.name+'/'+pmData.name;
                // console.log(this.project_smob[f]);
              }
            }
            // 课程
            let d_keys = Object.keys(data.data.datas);
            for(let d=0;d<d_keys.length;d++){
              let ap_keys = Object.keys(data.data.datas[d_keys[d]]);
              for(let a=0;a<ap_keys.length;a++){
                let t_keys = Object.keys(data.data.datas[d_keys[d]][ap_keys[a]]);
                for(let t=0;t<t_keys.length;t++){
                  let courses = data.data.datas[d_keys[d]][ap_keys[a]][t_keys[t]];
                  // console.log("courses ",courses);
                  let c_keys = Object.keys(courses);
                  let cl = c_keys.length;
                  for(let c=0;c<cl-1;c++){
                    let course = courses[c_keys[c]];
                    // console.log(c_keys[c],"course ",course);
                    let time0 = courses[c_keys[(cl-1)]][0];
                    this.project_smob[d][c_keys[c]-1].list.push(course);
                    let list_index = this.project_smob[d][c_keys[c]-1].list.length - 1;
                    this.project_smob[d][c_keys[c]-1].list[list_index].time = time0;
                    // console.log("308  ",courses[c_keys[(cl-1)]],this.project_smob[d][c_keys[c]-1].list);
                  }
                }
              }
            }
            // console.log("this.project_smob ",this.project_smob);

            this.addCourses();

            // spc端添加DOM数据
            // 日期
            var datas_keys = Object.keys(data.data.datas);
            for (let i = 0; i < datas_keys.length; i++) {
              this.days.push(this.formatTime(datas_keys[i], "start"));
              // console.log(this.days,this.days.length,datas_keys,datas_keys.length);
              // 日程
              var finishing_keys = Object.keys(
                data.data.finishing[datas_keys[i]]
              );
              var finishing_nums = finishing_keys.length;
              var has_am = false;
              var has_pm = false;
              var finishing_str = JSON.stringify(finishing_keys);
              if (finishing_str.indexOf("am") != -1) {
                has_am = true; //判断是否有上午
              }
              if (finishing_str.indexOf("pm") != -1) {
                has_pm = true; //判断是否有下午
              }
              if (has_am && has_pm) {
                finishing_nums = finishing_nums / 2; //根绝上下午判断循环次数
              }

              var toptr = "";
              var kechengtr = "";
              var trimg = require("../assets/img/time.png");
              var trstr1 = "";
              var trstr2 = "";
              var fpstr = "";
              var pstr = "";
              // var course_item,c_item;

              var style1 =
                "cursor: pointer;border: 1px solid #dddddd;border-right: none;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
              var style2 =
                "cursor: pointer;border: 1px solid #dddddd;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
              var style3 =
                "cursor: pointer;border: 1px solid #dddddd;border-right: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
              var style4 =
                "cursor: pointer;border: 1px solid #dddddd;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
              var style_biaoti =
                "color: #1f63af;font-size: 16px;line-height: 22px;padding: 0 10px;width: 100%;overflow: hidden;margin-bottom: 5px;";
              var style_h2 =
                "font-size: 14px;color: #333333;font-weight: bold;margin-top: 10px;margin-bottom: 0;";
              var str2_1 = `<td valign="middle" class="course-href" style="${style1}" data-id>敬请期待</td>`;
              var str2_2 = `<td valign="middle" class="course-href" style="${style2}" data-id>敬请期待</td>`;
              var str2_3 = `<td valign="middle" class="course-href" style="${style3}" data-id>敬请期待</td>`;
              var str2_4 = `<td valign="middle" class="course-href" style="${style4}" data-id>敬请期待</td>`;

              var timesData_keys = Object.keys(data.data.timesData);
              // console.log(timesData_keys)

              // day1 上午 8:45 9:35 10:25 11:15
              // day2 上午 9:00 10:00 11:00
              // 仅适用于2021
              // let pmBegin = 4;
              // if(i==0){
              //  // timesData_keys = ["8:45", "9:35", "10:25", "11:15", "13:30", "14:30", "15:50", "16:50"];
              //  timesData_keys = ["8:45", "9:00", "9:35", "10:00", "10:25", "11:00", "11:15", "13:30", "14:30", "15:50", "16:50"];
              //  pmBegin = 7;
              // }
              // if(i==1){
              //  timesData_keys = ["9:00", "10:00", "11:00", "13:30", "14:30", "15:50", "16:50"];
              //  pmBegin = 3;
              // }
              // 0513修整：day1上午场2场3修改（0-5）
              // 0629修整：day2上午场3场4修改（0-5）
              let pmBegin = 3;
              timesData_keys = ["9:00", "10:00", "11:00", "13:30", "14:30", "15:50", "16:50"];
              let timesData_keys_new = ["8:45", "9:35", "10:25", "11:15"];

              var showli = `<li class="show" id="item${i + 1}">
                   <h4 style="text-align: center;margin-top: 20px;margin-bottom: 20px;font-weight: 500;font-size: 40px;padding-top: 45px;padding-bottom: 35px;">DAY${
                     i + 1
                   }</h4>
                   <table class="title" style="border-collapse: collapse;border-spacing: 0;">
                     <tbody class="con_parent">
                     </tbody>
                   </table>
                 </li>`;
              $("ul.content_spc").append(showli);

              // 上午
              if (has_am) {
                // 标题 trstr1
                trstr1 =
                  `<td  style="width: 7%;height: 95px;padding-left: 0px;border: 1px solid #dddddd;border-right: none;border-bottom: none;font-size: 18px;color: #000000;font-weight: bold;">
                     <img src="${trimg}" alt="" />
                   </td>`;
                for (let j = 0; j < finishing_nums; j++) {
                  fpstr = data.data.finishing[datas_keys[i]][j + 1 + "am"].producers;
                  pstr = fpstr != "" && fpstr != null ? fpstr : "大咖正在就位";
                  // console.log("pro ",this.project);
                  let tid;
                  for(let pl=0;pl<this.project.length;pl++){
                    if(this.project[pl].venueName == data.data.finishing[datas_keys[i]][j + 1 + "am"].name){
                      tid = this.project[pl].id;
                    }
                  }
                  if (j < finishing_nums - 1) {
                    trstr1 +=
                      `<td data-id="${tid}" class="schedule-title" style="border: 1px solid #dddddd;border-right: none;border-bottom: none;height: 95px;width: 10%;font-size: 18px;color: #000000;font-weight: bold;padding-left: 14px;">
                         ${data.data.finishing[datas_keys[i]][j + 1 + "am"].name}
                         <br><span class="chup" style="font-size: 14px;">出品人：${pstr}</span>
                       </td>`;
                  } else {
                    trstr1 +=
                      `<td data-id="${tid}" class="schedule-title" style="border: 1px solid #dddddd;border-bottom: none;height: 95px;width: 10%;font-size: 18px;color: #000000;font-weight: bold;padding-left: 14px;">
                         ${data.data.finishing[datas_keys[i]][j + 1 + "am"].name}
                         <br><span class="chup" style="font-size: 14px;">出品人：${pstr}</span>
                       </td>`;
                  }
                }
                // 课程 trstr2
                // for (let t = 0;t < Math.floor(timesData_keys.length / 2);t++) {
                for (let t = 0;t < pmBegin;t++) {
                  trstr2 =
                    `<td style="border: 1px solid #dddddd;border-right: none;border-bottom: none;background: #fafbfc;width: 105px;height: 180px;font-size: 16px;color: #000000;text-align: center;line-height: 24px;padding-left: 0;">
                       ${data.data.timesData[timesData_keys[t]][0]}<br />-<br />
                       ${data.data.timesData[timesData_keys[t]][1]}
                     </td>`;
                  // console.log(trstr2,i,timesData_keys[t]," am: ",data.data.datas[datas_keys[i]]['am'][timesData_keys[t]])
                  var datas_am_keys = Object.keys(data.data.datas[datas_keys[i]]["am"][timesData_keys[t]]);
                  // console.log(datas_am_keys);
                  for (let s = 0; s < 6; s++) {
                    let str2_ = "";
                    for (let k = 0; k < datas_am_keys.length - 1; k++) {
                      let cid = data.data.datas[datas_keys[i]]["am"][timesData_keys[t]][datas_am_keys[k]].courseId;
                      let ctitle = data.data.datas[datas_keys[i]]["am"][timesData_keys[t]][datas_am_keys[k]].courseTitle;
                      let cname = data.data.datas[datas_keys[i]]["am"][timesData_keys[t]][datas_am_keys[k]].lecturers[0].name;
                      let ccompany = data.data.datas[datas_keys[i]]["am"][timesData_keys[t]][datas_am_keys[k]].lecturers[0].company;
                      let cposition = data.data.datas[datas_keys[i]]["am"][timesData_keys[t]][datas_am_keys[k]].lecturers[0].position;
                      // console.log("478 ",datas_am_keys[k],datas_am_keys.length,t);
                      if (s + 1 == datas_am_keys[k]) {
                        if (s < 5) {
                          // 2021
                          // day1上午场3场4(对应s=2和s=3)数据有差异，抽取数据另行生成表格
                          // day2上午场4场5(对应s=3和s=4)
                          // if(i==0){
                            style1 =
                              "height: 260px;cursor: pointer;border: 1px solid #dddddd;border-right: none;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
                            style2 =
                              "height: 260px;cursor: pointer;border: 1px solid #dddddd;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
                          // }
                          if(!((i==0&&(s==2||s==3))||(i==1&&(s==3||s==4)))){
                            // li位置非最后一列
                            str2_ =
                            `<td valign="middle" class="course-href" style="${style1}" data-id="${cid}">
                               <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                               <div class="ren" style="width: 100%;padding: 2px;">
                                 <h2 style="${style_h2}">${cname}</h2>
                                 <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                   ${ccompany}<br>${cposition}
                                 </p>
                               </div>
                             </td>`; // 有数据
                             // <a href="/course?id=${cid}">
                             // </a>
                          }
                        } else {
                          str2_ =
                          `<td valign="middle" class="course-href" style="${style2}" data-id="${cid}">
                             <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                             <div class="ren" style="width: 100%;padding: 2px;">
                               <h2 style="${style_h2}">${cname}</h2>
                               <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                 ${ccompany}<br>${cposition}
                               </p>
                             </div>
                           </td>`; // 有数据
                        }
                      }
                    }
                    if (s < 5) {
                      // 2021
                      if ((i==0 && t==0 && (s==2 || s==3))||(i==1 && t==0 && (s==3 || s==4))){
                        let str2_1_n = `<td valign="middle" class="course-href" style="${style1}height: 195px;padding:34% 0;" data-id>敬请期待</td>`;
                        trstr2 +=
                          `<td valign="top" rowspan="3" class="course-href" id="table${i}_td${s+1}"" style="border: none;" data-id>
                             <table class="con_child" Width="100%">
                               <tbody class="con_child_body">
                                <tr class="tr0">${str2_1_n}</tr>
                                <tr class="tr1">${str2_1_n}</tr>
                                <tr class="tr2">${str2_1_n}</tr>
                                <tr class="tr3">${str2_1_n}</tr>
                               </tbody>
                             </table>
                           </td>`;
                      }
                      else if (i==0 && t==0 && (s == 3)){
                        trstr2 += '';
                      }
                      else if((i == 0 && t!=0 && (s == 2 || s == 3))||(i==1 && t!=0 && (s==3 || s==4))){
                        trstr2 += `<td valign="middle" class="course-href" style="${style1}display:none;" data-id>敬请期待</td>`;
                      }else{
                        // li位置非最后一列
                        trstr2 += str2_ != "" ? str2_ : str2_1;
                      }
                    } else {
                      trstr2 += str2_ != "" ? str2_ : str2_2;
                    }
                  }
                  kechengtr += `<tr class="kecheng">${trstr2}</tr>`;
                }
              }
              toptr = `<tr class="top">${trstr1}</tr>`;
              $("#item" + (i + 1) + " .title .con_parent").append(toptr);
              $("#item" + (i + 1) + " .title .con_parent").append(kechengtr);
              // 2021 timesData_keys_new=["8:45", "9:35", "10:25", "11:15"]
              // day1 item1 2 3
              // day2 item2 3
              for (let tn = 0; tn < timesData_keys_new.length; tn++) {
                var datas_am_keys_new = Object.keys(data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]]);
                let str_new3 = "";
                let str_new4 = "";
                let str_new14 = "";
                let str_new15 = "";
                for (let kn = 0; kn < datas_am_keys_new.length - 1; kn++) {
                  // console.log(tn,kn, datas_am_keys_new[kn]);
                  let cid = data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]][datas_am_keys_new[kn]].courseId;
                  let ctitle = data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]][datas_am_keys_new[kn]].courseTitle;
                  let cname = data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]][datas_am_keys_new[kn]].lecturers[0].name;
                  let ccompany = data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]][datas_am_keys_new[kn]].lecturers[0].company;
                  let cposition = data.data.datas[datas_keys[i]]["am"][timesData_keys_new[tn]][datas_am_keys_new[kn]].lecturers[0].position;
                  let str_new = `<td valign="middle" class="course-href" style="${style1}height: 195px;padding-top: 8px;padding-bottom: 8px;" data-id="${cid}">
                                   <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                                   <div class="ren" style="width: 100%;padding: 2px;">
                                     <h2 style="${style_h2}">${cname}</h2>
                                     <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                       ${ccompany}<br>${cposition}
                                     </p>
                                   </div>
                                 </td>`
                  if(datas_am_keys_new[kn]==3){
                    // console.log("0 3 ",cid,ctitle,cname,ccompany,cposition);
                    str_new3 = str_new;
                  }
                  if(i==0&&datas_am_keys_new[kn]==4){
                    // console.log("0 4 ",cid,ctitle,cname,ccompany,cposition);
                    str_new4 = str_new;
                  }
                  if(i==1&&datas_am_keys_new[kn]==4){
                    // console.log("1 4 ",cid,ctitle,cname,ccompany,cposition,str_new);
                    str_new14 = str_new;
                  }
                  if(i==1&&datas_am_keys_new[kn]==5){
                    // console.log("1 5 ",cid,ctitle,cname,ccompany,cposition,str_new);
                    str_new15 = str_new;
                  }
                }
                // console.log(i,tn,str_new3,str_new4);
                // let str2_1_n = `<td valign="middle" class="course-href" style="${style1}height: 195px;" data-id>敬请期待</td>`
                // str_new3 = str_new3 ? str_new3 : str2_1_n;
                // str_new4 = str_new4 ? str_new4 : str2_1_n;
                // $("#item" + (i + 1) + " #tabletd3 .con_child .con_child_body .tr"+tn).empty();
                // $("#item" + (i + 1) + " #tabletd3 .con_child .con_child_body .tr"+tn).append(str_new3);
                // $("#item" + (i + 1) + " #tabletd3 .con_child .con_child_body .tr"+tn).append(str_new4);
                if(str_new3){
                  $("#item" + (i + 1) + " #table0_td3 .con_child .con_child_body .tr"+tn).empty();
                  $("#item" + (i + 1) + " #table0_td3 .con_child .con_child_body .tr"+tn).append(str_new3);
                }
                if(str_new4){
                  $("#item" + (i + 1) + " #table0_td4 .con_child .con_child_body .tr"+tn).empty();
                  $("#item" + (i + 1) + " #table0_td4 .con_child .con_child_body .tr"+tn).append(str_new4);
                }
                if(str_new14){
                  $("#item" + (i + 1) + " #table1_td4 .con_child .con_child_body .tr"+tn).empty();
                  $("#item" + (i + 1) + " #table1_td4 .con_child .con_child_body .tr"+tn).append(str_new14);
                }
                if(str_new15){
                  $("#item" + (i + 1) + " #table1_td5 .con_child .con_child_body .tr"+tn).empty();
                  $("#item" + (i + 1) + " #table1_td5 .con_child .con_child_body .tr"+tn).append(str_new15);
                }
              }

              // 下午
              if (has_pm) {
                trstr1 =
                  `<td  style="width: 7%;height: 95px;padding-left: 0px;border: 1px solid #dddddd;border-right: none;border-bottom: none;font-size: 18px;color: #000000;font-weight: bold;">
                     <img src="${trimg}" alt="" />
                   </td>`;
                kechengtr = "";
                for (let j = 0; j < finishing_nums; j++) {
                  fpstr = data.data.finishing[datas_keys[i]][j + 1 + "pm"].producers;
                  pstr = fpstr != "" && fpstr != null ? fpstr : "大咖正在就位";
                  // console.log("pro ",this.project);
                  let tid;
                  for(let pl=0;pl<this.project.length;pl++){
                    if(this.project[pl].venueName == data.data.finishing[datas_keys[i]][j + 1 + "pm"].name){
                      tid = this.project[pl].id;
                    }
                  }
                  if (j < finishing_nums - 1) {
                    trstr1 +=
                    `<td data-id="${tid}" class="schedule-title" style="border: 1px solid #dddddd;border-right: none;border-bottom: none;height: 95px;width: 10%;font-size: 18px;color: #000000;font-weight: bold;padding-left: 14px;">
                       ${data.data.finishing[datas_keys[i]][j + 1 + "pm"].name}
                       <br><span class="chup" style="font-size: 14px;">出品人：${pstr}</span>
                     </td>`;
                  } else {
                    trstr1 +=
                    `<td data-id="${tid}" class="schedule-title" style="border: 1px solid #dddddd;border-bottom: none;height: 95px;width: 10%;font-size: 18px;color: #000000;font-weight: bold;padding-left: 14px;">
                       ${data.data.finishing[datas_keys[i]][j + 1 + "pm"].name}
                       <br><span class="chup" style="font-size: 14px;">出品人：${pstr}</span>
                     </td>`;
                  }
                }
                // 课程 trstr2
                style1 =
                  "cursor: pointer;border: 1px solid #dddddd;border-right: none;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
                style2 =
                  "cursor: pointer;border: 1px solid #dddddd;border-bottom: none;width: 10%;background: #fafbfc;text-align: center;font-size: 14px;color: #333;padding-top: 8px;padding-bottom: 8px;";
                // for (let t = Math.floor(timesData_keys.length / 2);t < timesData_keys.length;t++) {
                for (let t = pmBegin;t < timesData_keys.length;t++) {
                  if (t < timesData_keys.length - 1) {
                    trstr2 =
                    `<td style="border: 1px solid #dddddd;border-right: none;border-bottom: none;background: #fafbfc;width: 105px;height: 180px;font-size: 16px;color: #000000;text-align: center;line-height: 24px;padding-left: 0;">
                       ${data.data.timesData[timesData_keys[t]][0]}<br />-<br />
                       ${data.data.timesData[timesData_keys[t]][1]}
                     </td>`;
                  } else {
                    trstr2 =
                    `<td style="border: 1px solid #dddddd;border-right: none;background: #fafbfc;width: 105px;height: 180px;font-size: 16px;color: #000000;text-align: center;line-height: 24px;padding-left: 0;">
                       ${data.data.timesData[timesData_keys[t]][0]}<br />-<br />
                       ${data.data.timesData[timesData_keys[t]][1]}
                     </td>`;
                  }
                  // console.log(i,timesData_keys[t]," pm: ",data.data.datas[datas_keys[i]]['pm'][timesData_keys[t]])
                  var datas_pm_keys = Object.keys(
                    data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]]
                  );
                  // console.log(datas_pm_keys);
                  for (let s = 0; s < 6; s++) {
                    let str2_ = "";
                    for (let k = 0; k < datas_pm_keys.length - 1; k++) {
                      let cid = data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]][datas_pm_keys[k]].courseId;
                      let ctitle = data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]][datas_pm_keys[k]].courseTitle;
                      let cname = data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]][datas_pm_keys[k]].lecturers[0].name;
                      let ccompany = data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]][datas_pm_keys[k]].lecturers[0].company;
                      let cposition = data.data.datas[datas_keys[i]]["pm"][timesData_keys[t]][datas_pm_keys[k]].lecturers[0].position;
                      if (s + 1 == datas_pm_keys[k]) {
                        if (t < timesData_keys.length - 1) {
                          // li位置非最后一行
                          if (s < 5) {
                            // li位置非最后一列
                            str2_ =
                            `<td valign="middle" class="course-href" style="${style1}" data-id="${cid}">
                               <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                               <div class="ren" style="width: 100%;padding: 2px;">
                                 <h2 style="${style_h2}">${cname}</h2>
                                 <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                   ${ccompany}<br>${cposition}
                                 </p>
                               </div>
                             </td>`; // 有数据
                          } else {
                            str2_ =
                            `<td valign="middle" class="course-href" style="${style2}" data-id="${cid}">
                               <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                               <div class="ren" style="width: 100%;padding: 2px;">
                                 <h2 style="${style_h2}">${cname}</h2>
                                 <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                   ${ccompany}<br>${cposition}
                                 </p>
                               </div>
                             </td>`; // 有数据
                          }
                        } else {
                          if (s < 5) {
                            str2_ =
                            `<td valign="middle" class="course-href" style="${style3}" data-id="${cid}">
                               <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                               <div class="ren" style="width: 100%;padding: 2px;">
                                 <h2 style="${style_h2}">${cname}</h2>
                                 <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                   ${ccompany}<br>${cposition}
                                 </p>
                               </div>
                             </td>`; // 有数据
                          } else {
                            str2_ =
                            `<td valign="middle" class="course-href" style="${style4}" data-id="${cid}">
                               <div class="biaoti" style="${style_biaoti}">${ctitle}</div>
                               <div class="ren" style="width: 100%;padding: 2px;">
                                 <h2 style="${style_h2}">${cname}</h2>
                                 <p style="font-size: 14px;color: #666666;padding-top: 5px;padding-left: 5px;">
                                   ${ccompany}<br>${cposition}
                                 </p>
                               </div>
                             </td>`; // 有数据
                          }
                        }
                      }
                    }
                    if (t < timesData_keys.length - 1) {
                      // li位置非最后一行
                      if (s < 5) {
                        // li位置非最后一列
                        trstr2 += str2_ != "" ? str2_ : str2_1;
                      } else {
                        trstr2 += str2_ != "" ? str2_ : str2_2;
                      }
                    } else {
                      if (s < 5) {
                        trstr2 += str2_ != "" ? str2_ : str2_3;
                      } else {
                        trstr2 += str2_ != "" ? str2_ : str2_4;
                      }
                    }
                  }
                  kechengtr += `<tr class="kecheng">${trstr2}</tr>`;
                }
              }
              toptr = `<tr class="top">${trstr1}</tr>`;
              $("#item" + (i + 1) + " .title .con_parent").append(toptr);
              $("#item" + (i + 1) + " .title .con_parent").append(kechengtr);

              // 点击跳转
              for(let t=0;t<$("#item"+(i+1)+" table tbody .top td").length;t++){
                $("#item"+(i+1)+" table tbody .top td")[t].onclick = (e)=>{
                  // console.log("top ",e);
                  this.toNext('subject',$(e.currentTarget).attr('data-id'));
                }
              }
              for(let td=0;td<$("#item"+(i+1)+" table tbody .kecheng td").length;td++){
                $("#item"+(i+1)+" table tbody .kecheng td")[td].onclick = (e)=>{
                  // console.log("kecheng ",e);
                  this.toNext('course',$(e.currentTarget).attr('data-id'));
                }
              }
            }
          }
        });
      // this.formatTime(1624550400, "start");
    },
    getScheduleMob: function(){
      var url = "https://api.msup.com.cn/scheduling/get-schedule/2682";
      this.$http
        .jsonp(url)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("smob ",res.data);
            // 专题
            let fkeys = Object.keys(res.data.finishing);
            for(let f=0;f<fkeys.length;f++){
              this.project_smob.push([]);
              let dkeys = Object.keys(res.data.finishing[fkeys[f]]);
              
              var dnums = dkeys.length;
              var has_am = false;
              var has_pm = false;
              var dstr = JSON.stringify(dkeys);
              if (dstr.indexOf("am") != -1) {
                has_am = true; //判断是否有上午
              }
              if (dstr.indexOf("pm") != -1) {
                has_pm = true; //判断是否有下午
              }
              if (has_am && has_pm) {
                dnums = dnums / 2; //根绝上下午判断循环次数
              }

              for(let d=0;d<dnums;d++){
                let amData = res.data.finishing[fkeys[f]][(d+1)+'am'];
                let pmData = res.data.finishing[fkeys[f]][(d+1)+'pm'];
                // console.log(amData,this.formatTime(amData.date,'day'));
                // console.log(pmData,this.formatTime(pmData.date,'day'));
                this.project_smob[f].push({title:'', date:this.formatTime(pmData.date,'day'), list:[]});
                this.project_smob[f][d].title = amData.name+'/'+pmData.name;
                // console.log(this.project_smob[f]);
              }
            }
            // 课程
            let d_keys = Object.keys(res.data.datas);
            for(let d=0;d<d_keys.length;d++){
              let ap_keys = Object.keys(res.data.datas[d_keys[d]]);
              for(let a=0;a<ap_keys.length;a++){
                let t_keys = Object.keys(res.data.datas[d_keys[d]][ap_keys[a]]);
                for(let t=0;t<t_keys.length;t++){
                  let courses = res.data.datas[d_keys[d]][ap_keys[a]][t_keys[t]];
                  // console.log("courses ",courses);
                  let c_keys = Object.keys(courses);
                  let cl = c_keys.length;
                  for(let c=0;c<cl-1;c++){
                    let course = courses[c_keys[c]];
                    // console.log(c_keys[c],"course ",course);
                    this.project_smob[d][c_keys[c]-1].list.push(course);
                  }
                }
              }
            }
            // console.log(this.project_smob);
          }
        });
      
      // var project = [];
      // var url = "https://api.msup.com.cn/group/get-group-project/2682";
      // this.$http
      //   .jsonp(url)
      //   .then(data => {
      //     return data.json();
      //   })
      //   .then(data => {
      //     if(data.errno == 0){
      //       // console.log("data: ",data.data);
      //       project = data.data;
      //       this.project_smob = project;
      //       console.log("smob ",this.project_smob,this.days);
      //       // k 0       1       2         3           4           5           6
      //       // n 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23
      //       // d 1   2   1   2   1   2     1     2     1     2     1     2

      //       // d1 4k / 4k+1
      //       // d2 4k+2 / 4k+3
      //     }
      //   })
    },
    toNext: function(p,id){
      switch(p){
        case 'subject':
          if(id){
            this.$router.push({path:"/subject",name:"OneSubject",query:{id:id}});
          }
          break;
        case 'course':
          if(id){
            this.$router.push({path:"/course",name:"OneCourse",query:{id:id}});
          }
          break;
        default:
          break;
      }
    }
  },
  created() {
    // this.clickLi();
    this.getSpeech();
    this.getSchedule();
    // this.getScheduleMob();
  },
  mounted() {
    this.clickLi();
    // this.getSchedule();
    // this.getScheduleMob();
  },
};
</script>

<style lang="scss" scoped>
a,a:hover,a:visited {
  color: #000000;
  text-decoration: none;
}
// div
h1,h2,h3,h4,h5,h6,p,a,span,b,i,em {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

#schedule {
  // margin-top: 125px;
  text-align: left;
  .banner {
    // margin-top: 135px;
    width: 100%;
    height: 300px;
    background-color: #fafbfc;
    h1 {
      letter-spacing: 8px;
      line-height: 50px;
      padding-top: 125px;
      text-align: center;
      font-family: PingFangSCBold;
      font-size: 50px;
      font-weight: bold;
      color: #2c3e50;
    }
    p {
      line-height: 24px;
      padding-top: 6px;
      text-align: center;
      font-family: PingFangSCBold;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 4px;
      color: #666666;
    }
  }
  .schedule_con {
    width: 100%;
    // height: 500px;
    #menu {
      position: fixed;
      bottom: 50px;
      right: 50px;
      margin-left: 400px;
      width: 50px;
      ul {
        li {
          a {
            display: block;
            border: 1px solid #0088bb;
            margin: 5px 0;
            font-size: 14px;
            font-weight: bold;
            color: #333;
            width: 80px;
            height: 50px;
            line-height: 50px;
            text-decoration: none;
            text-align: center;
          }
          a:hover,
          a.current {
            border: 1px solid #003177;
            color: #fff;
            background: #003177;
          }
        }
      }
    }
    .richeng {
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 45px;
      .daohang {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        li {
          text-align: center;
          float: left;
          height: 100px;
          a {
            color: #000000;
          }
          div {
            margin: 0 auto;
            width: 80%;
            text-align: center;
            height: 100px;
            border: 1px solid #0b70a4;
            cursor: pointer;
            transition: all 0.3s;
          }
          div:hover {
            background: #003177;
            opacity: 0.8;
            color: #ffffff;
          }
          h1 {
            margin: 0;
            font-weight: 500;
            padding-top: 25px;
            font-size: 36px;
          }
          span {
            font-size: 14px;
            white-space: pre;
          }
        }
        .active {
          div {
            color: #ffffff;
            background: #003177;
            span {
              font-weight: 500;
            }
          }
        }
      }
      .content {
        max-width: 1300px;
        margin: 0 auto;
        li {
          h4 {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 40px;
            padding-top: 45px;
            padding-bottom: 35px;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
            .top {
              td {
                border: 1px solid #dddddd;
                border-right: none;
                border-bottom: none;
                height: 95px;
                width: 10%;
                font-size: 18px;
                color: #000000;
                font-weight: bold;
                padding-left: 14px;
                .chup {
                  font-size: 14px;
                }
              }
              td:first-child {
                width: 7%;
                height: 95px;
                padding-left: 0px;
              }
              td:last-child {
                border-right: 1px solid #dddddd;
              }
            }
            .kecheng {
              h2 {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-top: 10px;
              }
              p {
                font-size: 14px;
                color: #666666;
                padding-top: 5px;
                padding-left: 5px;
              }
              td {
                border: 1px solid #dddddd;
                border-right: none;
                border-bottom: none;
                width: 10%;
                background: #fafbfc;
                text-align: center;
                font-size: 14px;
                color: #333;
                .biaoti {
                  color: #1f63af;
                  font-size: 16px;
                  line-height: 22px;
                  padding: 0 10px;
                  width: 100%;
                  overflow: hidden;
                  margin-bottom: 5px;
                }
                .ren {
                  width: 100%;
                  padding: 2px;
                }
              }
              td:first-child {
                width: 105px;
                height: 180px;
                font-size: 16px;
                color: #000000;
                text-align: center;
                line-height: 24px;
                padding-left: 0;
              }
              td:last-child {
                border-right: 1px solid #dddddd;
              }
              .course-href {
                cursor: pointer;
              }
            }
            tr:last-child {
              border-bottom: 1px solid #dddddd;
            }
          }
        }
      }
      .daohang.smob{
        li{
          width: 33%;
          min-width: 135px;
          height: auto;
          // border: 1px solid #0b70a4;
          border: 1px solid #003177;
          h1{
            padding: 0;
            font-size: 30px;
            line-height: 60px;
          }
        }
        .active{
          // background: #0b70a4;
          background: #003177;
          h1{
            color: #ffffff;
          }
        }
      }
      .content_smob{
        ul.day_menu{
          margin-top: 30px;
          li{
            float: left;
            width: 46%;
            height: 60px;
            line-height: 60px;
            background: #f9f9f9;
            margin-bottom: 8px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 15px;display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          li:nth-of-type(2n+1){
            margin-left: 3%;
            margin-right: 1%;
          }
          li:nth-of-type(2n){
            margin-left: 1%;
          }
        }
        ul.day_list{
          margin-top: 10px;
          // display: none;
          // li{
          //   // 
          // }
        }
      }
    }
  }
}

.clearboth {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.spc{
  display: block;
}
.smob{
  display: none;
}

@media (max-width: 750px) {
  .spc{
    display: none;
  }
  .smob{
    display: block;
  } 
}
@media (max-width: 1023px) {
  #schedule{
    margin-top: 0;
  }
}
</style>